import React, { useContext } from 'react';
import { isNil } from 'lodash';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { redirect } from 'common/actions';
import { withContext } from 'common/helpers/context';
import {
  PermissionsModalContext,
  RequestsModalContext,
  ShareModalContext,
} from 'common/context';
import { requestPassportAccess } from '../../../components/Dashboard/components/CandidateSearch/components/SearchMatches/components/SearchActionMenu/actions/requestPassportAccess';
// import { addCandidateToPosition } from './actions/addCandidateToPosition';

const PassportActionMenu = (props) => {
  const {
    anchorEl,
    onClose,
    userInfo,
    candidate,
    feedback,
    showRequests,
    showPermissions,
    showShare,
  } = props;
  const {
    handleSetSelectCandidate: handleSetSelectCandidateRequests,
    handleShowRequestsModal,
  } = useContext(RequestsModalContext);
  const {
    handleSetSelectCandidate: handleSetSelectCandidatePermissions,
    handleShowPermissionsModal,
  } = useContext(PermissionsModalContext);
  const {
    handleSetSelectCandidate: handleSetSelectCandidateShare,
    handleShowShareModal,
  } = useContext(ShareModalContext);

  if (!candidate) return null;

  const { doneTechAssessment, doneCompetasDueDilligence } = candidate.flags;

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleClickRequestPassport = async () => {
    await props.requestPassportAccess(userInfo.id, candidate.id);
    onClose();
  };

  const handleClickViewRequests = (event) => {
    stopPropagation(event);
    onClose();
    handleSetSelectCandidateRequests(candidate);
    handleShowRequestsModal();
  };

  const handleClickManagePerms = (event) => {
    stopPropagation(event);
    onClose();
    handleSetSelectCandidatePermissions(candidate);
    handleShowPermissionsModal();
  };

  const handleClickShare = (event) => {
    stopPropagation(event);
    onClose();
    handleSetSelectCandidateShare(candidate);
    handleShowShareModal();
  };

  const isMySponsored =
    props.isMySponsored ||
    candidate?.assignment?.token?.sponsor?.id === userInfo.id;

  return (
    <Menu
      id="search-action-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      {!doneTechAssessment && !doneCompetasDueDilligence && (
        <MenuItem>
          <Button variant="contained" disabled>
            Awaiting Assessment
          </Button>
        </MenuItem>
      )}
      {doneTechAssessment && !doneCompetasDueDilligence && (
        <MenuItem>
          <Button variant="contained" disabled>
            Awaiting Assessment
          </Button>
        </MenuItem>
      )}
      {doneTechAssessment && doneCompetasDueDilligence && candidate && (
        <MenuItem>
          <Button
            component={Link}
            variant="contained"
            color="primary"
            to={`/passport-anon/${candidate.id}/1`}
            target="_blank"
            rel="noopener noreferrer"
            fullWidth
          >
            Anonymous Results
          </Button>
        </MenuItem>
      )}
      {doneTechAssessment && doneCompetasDueDilligence && candidate && (
        <MenuItem>
          <Button
            component={Link}
            variant="contained"
            color="primary"
            to={`/passport/${candidate.id}`}
            target="_blank"
            rel="noopener noreferrer"
            fullWidth
          >
            View Passport
          </Button>
        </MenuItem>
      )}
      {isNil(feedback) && !isMySponsored && (
        <MenuItem>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickRequestPassport}
            fullWidth
          >
            Request Passport
          </Button>
        </MenuItem>
      )}
      {showRequests && isMySponsored && (
        <MenuItem>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickViewRequests}
            fullWidth
          >
            View Requests
          </Button>
        </MenuItem>
      )}
      {showPermissions && isMySponsored && (
        <MenuItem>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickManagePerms}
            fullWidth
          >
            Manage Permissions
          </Button>
        </MenuItem>
      )}
      {showShare && isMySponsored && (
        <MenuItem>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickShare}
            fullWidth
          >
            Share
          </Button>
        </MenuItem>
      )}
    </Menu>
  );
};

PassportActionMenu.propTypes = {
  userInfo: PropTypes.object.isRequired,
  showPermissions: PropTypes.bool,
  showRequests: PropTypes.bool,
  showShare: PropTypes.bool,
};

PassportActionMenu.defaultProps = {
  isMySponsored: false,
  showPermissions: true,
  showRequests: true,
  showShare: true,
};

const mapStateToProps = (state) => {
  const { userInfo } = state.dashboard;
  return { userInfo };
};

export default connect(mapStateToProps, {
  redirect,
  requestPassportAccess,
  // addCandidateToPosition,
})(withContext(PassportActionMenu));
